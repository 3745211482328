<template>
  <div id="back">
    <form action autocomplete="off">
      <div class="row color-w login_item mb-2 mt-3">
        <div class="ml-3 btn-group-vertical">
          <img
            src="../../assets/img/sign/login_tel.png"
            class="login_icon"
            alt
          />
        </div>
        <div class>
          <input
            type="text"
            class="pl-2"
            maxlength="11"
            autofocus
            v-model="username"
            @input="numChange"
            placeholder="请输入手机号"
            autocomplete="off"
            id="username"
          />
        </div>
      </div>
      <div class="row color-w login_item mt-3 position-relative">
        <button
          type="button"
          class="position-absolute send_codes color-1f fz-14 cursor_pionter"
          :disabled="counts < 60 ? true : false"
        >
          {{ startTexts }}
        </button>
        <span
          class="position-absolute send_codes color-1f fz-14 cursor_pionter"
          @click="sendMessage"
          >{{ startTexts }}</span
        >
        <div class="ml-3 btn-group-vertical">
          <img
            src="../../assets/img/sign/login_icon_password.png"
            class="login_icon"
            alt
          />
        </div>
        <div>
          <input
            type="text"
            class="pl-2"
            placeholder="请输入验证码"
            maxlength="6"
            id="code"
            autocomplete="off"
          />
        </div>
      </div>
      <div class="row color-w login_item mt-3">
        <div class="ml-3 btn-group-vertical">
          <img
            src="../../assets/img/sign/icon_key.png"
            class="login_icon"
            alt
          />
        </div>
        <div>
          <input
            class="pl-2"
            :type="type"
            maxlength="20"
            autocomplete="off"
            placeholder="请设置6-20位新密码"
            id="passwordOne"
          />
        </div>
      </div>
      <div class="row color-w login_item mt-3">
        <div class="ml-3 btn-group-vertical">
          <img
            src="../../assets/img/sign/icon_key.png"
            class="login_icon"
            alt
          />
        </div>
        <div>
          <input
            :type="type"
            class="pl-2"
            autocomplete
            maxlength="20"
            placeholder="再次确认新密码"
            id="passwordTwo"
          />
        </div>
      </div>
      <div class="clearfix mt-3">
        <router-link to="/login" class="color-w fz-14 float-left"
          >立即登录</router-link
        >
      </div>
      <el-button
        @click="retrievePassword"
        type="primary"
        class="mt-3 mb-3"
        style="width: 100%"
        >确认修改</el-button
      >
    </form>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters({
      counts: "count",
      startTexts: "startText",
      errorTips: "errorTip",
    }),
    ...mapActions({
      clearCountCode: "clearCountCode",
    }),
  },

  data() {
    return {
      errorMessage: {},
      mobile: {},
      flag: false,
      username: "",
      type: "text",
    };
  },

  methods: {
    getCookie(name) {
      var value = "; " + document.cookie;
      var parts = value.split("; " + name + "=");

      if (parts.length === 2) return parts.pop().split(";").shift();
    },
    sendMessage() {
      if (this.flag || this.startTexts != "发送验证码") return false;

      var phone_number = this.username;
      this.flag = true;
      this.$http
        .post(window.BASE_URL + "/auth/sms/set_pwd/", {
          phone_number: phone_number,
        })
        .then(
          (res) => {
            this.flag = false;
            this.mobile = res.data;
            this.errorMessage = res.data;
            this.$store.dispatch("countCode");
          },
          (res) => {
            this.flag = false;
            var res = res.response;

            if (res.data.errMessage != null) {
              this.$store.state.sendCode.errorTip = res.data.errMessage;
            } else {
              if ((res.data.non_field_errors = !null)) {
                this.$store.state.sendCode.errorTip =
                  res.data.non_field_errors[0];
              } else if (res.data.phone_number != null) {
                this.$store.state.sendCode.errorTip = res.data.phone_number[0];
              } else {
                if (res.data.code != null) {
                  this.$store.state.sendCode.errorTip = res.data.code[0];
                } else {
                  if (res.data.passwordOne != null) {
                    this.$store.state.sendCode.errorTip =
                      res.data.passwordOne[0];
                  } else {
                    if (res.data.passwordTwo != null) {
                      this.$store.state.sendCode.errorTip =
                        res.data.passwordTwo[0];
                    } else {
                      if (res.data.hidden_number != null) {
                        this.$store.state.sendCode.errorTip = "请先发送验证码";
                      }
                    }
                  }
                }
              }
            }
          }
        );
    },
    numChange() {
      if ((this.$store.state.sendCode.errorTip = "请输入手机号")) {
        this.$store.state.sendCode.errorTip = "";
      }
    },
    retrievePassword() {
      var param = {};

      param.phone_number = this.username;
      param.hidden_number = this.mobile.mobile;
      param.password = $("#passwordOne").val();
      param.verification_code = $("#code").val();
      param.password_repeat = $("#passwordTwo").val();

      this.$http
        .post(window.BASE_URL + "/auth/users/set_pwd/", param, {
          headers: { "X-CSRFToken": this.getCookie("csrftoken") },
        })
        .then(
          (res) => {
            this.$Message.success("密码修改成功");
            //跳转回登录页面
            this.$router.push("/Login");
          },
          (res) => {
            res = res.response;

            if (res.data.errMessage != null) {
              this.$store.state.sendCode.errorTip = res.data.errMessage;
            } else {
              if (res.data.non_field_errors != null) {
                this.$store.state.sendCode.errorTip =
                  res.data.non_field_errors[0];
              } else if (res.data.phone_number != null) {
                this.$store.state.sendCode.errorTip = res.data.phone_number[0];
              } else {
                if (res.data.verification_code != null) {
                  this.$store.state.sendCode.errorTip =
                    res.data.verification_code[0];
                } else {
                  if (res.data.password != null) {
                    this.$store.state.sendCode.errorTip = res.data.password[0];
                  } else {
                    if (res.data.password_repeat != null) {
                      this.$store.state.sendCode.errorTip =
                        res.data.password_repeat[0];
                    } else {
                      if (res.data.hidden_number != null) {
                        this.$store.state.sendCode.errorTip = "请先发送验证码";
                      }
                    }
                  }
                }
              }
            }
          }
        );
    },
  },
  created() {
    this.username = this.$route.query.phone;
    this.$store.state.sendCode.errorTip = "";
  },
  mounted() {
    setTimeout(() => {
      this.type = "password";
    }, 1e3);
  },
  destroyed() {
    this.clearCountCode.then();
  },
};
</script>

<style>
#back {
  width: 30%;
  margin: 0 auto;
}
.send_codes {
  height: 30px;
  right: 20px;
  letter-spacing: 2px;
  background: #fff;
}
</style>
